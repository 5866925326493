exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-how-to-play-js": () => import("./../../../src/pages/how-to-play.js" /* webpackChunkName: "component---src-pages-how-to-play-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-week-1-js": () => import("./../../../src/pages/week1.js" /* webpackChunkName: "component---src-pages-week-1-js" */),
  "component---src-pages-week-2-js": () => import("./../../../src/pages/week2.js" /* webpackChunkName: "component---src-pages-week-2-js" */)
}

